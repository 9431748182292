import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery';
import { collection, getDocs, addDoc, orderBy, query } from "firebase/firestore";
import { db } from '../firebaseConfig'
import { MapMarker, Map, CustomOverlayMap } from "react-kakao-maps-sdk";
import '../js/common.js'

const Introduction = () => {

    const historyCollectionRef = collection(db, "history");
    const [historyData, setHistoryData] = useState();

    useEffect(() => {
        const getHistory = async () => {
            const q = query(historyCollectionRef, orderBy("title", "desc"))
            const data = await getDocs(q);
            setHistoryData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getHistory();

    }, [])

    useEffect(() => {

        var IDX = undefined

        const moveSlider = (n) => {
            const w = 80
            let scrollLeft = $(".his-el").scrollLeft()
            scrollLeft += (w * n)
            $(".his-el").stop().animate({
                scrollLeft: scrollLeft
            }, 100, "linear")
        }

        // if (!isMobile()) {
        //     $(".his-el .ele .box ul").mCustomScrollbar({
        //         theme: "dark"
        //     });
        // } 

        // if (isMobileAndTablet()) {
        //     $(".his-arw").css("display", "none")
        // }

        $(".his-arw > div").on("mousedown", e => {
            const children = e.target.parentElement.children
            children.forEach((item, idx) => {
                if (e.target == item) {
                    IDX = idx
                }
            })
        })

        $(".his-arw > div").on("mouseup mouseleave", e => {
            IDX = undefined
            $(".his-el").stop()
        })

        setInterval(() => {
            if (IDX === 0) {
                moveSlider(-1)
            } else if (IDX === 1) {
                moveSlider(1)
            }
        }, 100)

    }, []);



    return (
        <>
            <div className="body intro">
                <main>

                    <section className="tpi bg_01">
                        <div className="wrp flx-center">
                            <div className="msg">
                                <div className="tit">핀테크 소개</div>
                                <div className="mit">대한민국 최고의 마이데이터 솔루션 서비스 기업, (주)핀테크</div>
                            </div>
                        </div>
                    </section>

                    <section className="com-his" id="history">
                        <div className="s_tit">
                            <span>연혁</span>
                        </div>
                        <div className="wrp w192">
                            <div className="his-el nscr">
                                {
                                    historyData &&
                                    historyData.map((item, index) => {
                                        return (
                                            <div className={index === 0 ? "ele now" : "ele"} key={index}>
                                                <div className="year">{item.title}</div>
                                                <div className="box" dangerouslySetInnerHTML={{ __html: item.content }}>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="his-arw">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </section>

                    <section className="map" id="location" style={{ backgroundColor: "white" }}>
                        <div className="s_tit">
                            <span>오시는 길</span>
                        </div>

                        {/* <!-- * 카카오맵 - 지도퍼가기 --> */}
                        {/* <!-- 1. 지도 노드 --> */}
                        {/* <div id="daumRoughmapContainer1609915246467" className="root_daum_roughmap root_daum_roughmap_landing"></div> */}

                        {/* <!--
				2. 설치 스크립트
				* 지도 퍼가기 서비스를 2개 이상 넣을 경우, 설치 스크립트는 하나만 삽입합니다.
			--> */}
                        {/* <script charSet="UTF-8" className="daum_roughmap_loader_script" src="https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js"></script> */}

                        {/* <!-- 3. 실행 스크립트 --> */}

                        {/* <script charSet="UTF-8">
                            {
                                new daum.roughmap.Lander({
                                    "timestamp" : "1676352768963",
                                    "key" : "2dqiy",
                                    "mapWidth" : "640",
                                    "mapHeight" : "360"
                                }).render()

                            }
                        </script> */}

                        <div className="root_daum_roughmap root_daum_roughmap_landing">
                            <Map center={{ lat: 37.522401565311, lng: 126.91972810828 }} style={{ width: "100%", height: "760px" }}>
                                <MapMarker position={{ lat: 37.522401565311, lng: 126.91972810828 }}>
                                </MapMarker>
                                <CustomOverlayMap position={{ lat: 37.522401565311, lng: 126.91972810828, }}>
                                    <div className="label" style={{ color: "#000" }}>
                                        <span className="left"></span>
                                        <span className="center">핀테크</span>
                                        <span className="right"></span>
                                    </div>
                                </CustomOverlayMap>
                            </Map>
                        </div>

                        <div className="wrp lci">
                            <ul>
                                <li className="adrs">주소</li>
                                <li>07251 서울특별시 영등포구 영신로 166, 9층(영등포동6가, 영등포 반도아이비밸리)</li>
                            </ul>

                            <ul>
                                <li className="phone">전화</li>
                                <li>Tel : 02-6956-4148</li>
                            </ul>

                            <ul>
                                <li className="email">메일</li>
                                <li>contact@fintech1.co.kr</li>
                            </ul>
                        </div>
                    </section>
                </main>
            </div>

        </>

    );

};

export default Introduction;