import React, { useEffect, useState } from 'react'

const PrivacyTerms = () => {
    const [iframeHeight, setIframeHeight] = useState();
    useEffect(() => {
        const iframe_screen = document.querySelector("#privacy_terms")
        iframe_screen.addEventListener('load', function () {
            setIframeHeight(iframe_screen.contentDocument.body.scrollHeight + 20)
        });
    }, [])


    return (
        <div className="terms">
            <main>
                <section className="tpi">
                    <iframe
                        id="privacy_terms"
                        src={'/htmlTerms/privacyTerms.html'}
                        width="100%"
                        height={iframeHeight}
                    ></iframe>
                </section>
            </main>
        </div>
    );

};

export default PrivacyTerms;
