import './App.css';
import './styles/globals.css'
import './css/common.css';
import { Header } from './components'
import { Footer } from './components'
import { Scroll } from './components'
import Index from './views';
import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import Introduction from './views/introduction';
import Service from './views/service';
import Recruit from './views/recruit';
import Report from './views/report';
import Write from './views/write';
import Setting from './views/setting';
import NewsDetail from './views/newsDetail';
import PrivacyTerms from './views/privacyTerms.js';
import './js/common.js'


const App = () => {

  return (
    // <div className="App">
    // <header className="App-header">
    <>
      <Router>
        <Scroll />
        <Header />
        <Routes>
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/privacyTerms" element={<PrivacyTerms />} />
          <Route path="/service" element={<Service />} />
          <Route path="/recruit" element={<Recruit />} />
          <Route path="/report" element={<Report />} />
          <Route path="/write" element={<Write />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/newsDetail" element={<NewsDetail />} />
          <Route path="/newsDetail" element={<NewsDetail />} />
          <Route path="/" element={<Index />} />
        </Routes>
        <Footer />
      </Router>
    </>
    // { </header> }
    // </div>
  );
}

export default App;
